import {
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { UploadFileType } from "../../../../../domain/models/upload-file-type";
import { useDocumentStore } from "../../../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import { DocumentGroupType } from "../../../../../domain/models/master";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useDoctorServiceStore } from "../../../../../store/doctor-service/DoctorServiceStore";
import { useAppointmentDetailsStore } from "../../../../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import Swal from "sweetalert2";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../../../../base";
import useRecaptcha from "../../../../hooks/reCaptcha";
import FileUpload from "../../../FileUpload";

interface AddDocumentModalInterface {
  source?: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  maxHeight: "90%",
  bgcolor: "white",
  borderRadius: "22px",
  boxShadow: 24,
  p: 4,
};

const AddDocumentModal: React.FC<AddDocumentModalInterface> = ({ source }) => {
  const {
    programDocuments,
    toBeUploaded,
    generateSignedUrl,
    uploadToBucket,
    handleCloseDocumentModal,
    showAddNewDocumentModal,
    uploadConfirmed,
    confirmUpload,
    storeDocument,
  } = useDocumentStore();

  const { appointmentDocumentTypes } = useAppointmentDetailsStore();
  const {
    generateUrlUsingDoctorService,
    uploadToBucketUsingDoctorService,
    ConfirmFileUploadUsingDoctorService,
  } = useDoctorServiceStore();
  const { addImagesToUpload } = useAppointmentDetailsStore();
  const { selectedCard, reloadPage } = useEnrollmentMainStore();
  const [documentGroup, setDocumentGroup] = useState("");
  const [documentType, setDocumentType] = useState<DocumentGroupType[]>([]);
  const [document, setDocument] = useState("");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [preview, setPreview] = useState("");
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  const {
    captchaToken: generateUrlUsingDoctorServiceToken,
    executeRecaptcha: executeGenerateUrlUsingDoctorServiceRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "generateUrlUsingDoctorService");

  const {
    captchaToken: confirmFileUploadUsingDoctorServiceToken,
    executeRecaptcha: executeConfirmFileUploadUsingDoctorServiceRecaptcha,
  } = useRecaptcha(
    GOOGLE_RECAPTCHE_SITE_KEY,
    "confirmFileUploadUsingDoctorService"
  );
  const {
    captchaToken: addImagesToUploadToken,
    executeRecaptcha: executeAddImagesToUploadRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "addImagesToUpload");

  const handleSelectDocumentGroup = (e: any) => {
    setDocumentGroup(e.target.value);
    if (programDocuments.length > 0) {
      for (let i = 0; i < programDocuments.length; i++) {
        if (programDocuments[i].code == e.target.value)
          setDocumentType(programDocuments[i].document_types);
      }
    } else {
      setDocumentType([]);
    }
  };

  const handleSelectDocument = (e: any) => {
    setDocument(e.target.value);
  };

  const handleUploadDocument = (file: File | null) => {
    setUploadLoading(true);
    if (source === "appointment") {
      handleCloseDocumentModal();
      Swal.fire({
        title: "Uploading Image...",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      executeGenerateUrlUsingDoctorServiceRecaptcha();
      executeConfirmFileUploadUsingDoctorServiceRecaptcha();
      executeAddImagesToUploadRecaptcha();
    } else {
      setUploadLoading(true);
      generateSignedUrl(file, document, documentGroup)
        .then((res: any) => uploadToBucket(file, res.data))
        .then((res: any) => confirmUpload(res))
        .then((res: any) =>
          storeDocument(selectedCard.id, res.uuid, document, documentGroup)
        )
        .then((res: any) => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Document uploaded sucessfully",
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            });
            setUploadLoading(false);
            reloadPage();
          } else {
            setUploadLoading(false);
            Swal.fire("Document Upload Failed.", "", "error");
          }
        })
        .catch((error: any) => {
          setUploadLoading(false);
          Swal.fire("Document Upload Failed.", "", "error");
        });
    }
    // setImageFile(e.target.files[0]);
    // const objectUrl = URL.createObjectURL(e.target.files[0]);
    // setPreview(objectUrl);
  };

  useEffect(() => {
    if (
      generateUrlUsingDoctorServiceToken &&
      confirmFileUploadUsingDoctorServiceToken &&
      addImagesToUploadToken &&
      document
    ) {
      generateUrlUsingDoctorService(
        {
          file: imageFile,
          success: false,
        },
        document
      )
        .then((res: any) => uploadToBucketUsingDoctorService(res))
        .then((res: any) => ConfirmFileUploadUsingDoctorService(res))
        .then((res: any) => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Image uploaded sucessfully",
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            });

            addImagesToUpload({
              type: document,
              uuid: res.uuid,
              title: document,
            });
          } else {
            Swal.fire("Document Upload Failed.", "", "error");
          }
        })
        .catch((error: any) => {
          Swal.fire("Document Upload Failed.", "", "error");
        });
    }
  }, [
    generateUrlUsingDoctorServiceToken,
    confirmFileUploadUsingDoctorServiceToken,
    addImagesToUploadToken,
    document,
  ]);

  const handleSubmit = () => {
    // if (source === "appointment") {
    //   handleCloseDocumentModal();
    //   Swal.fire({
    //     title: "Uploading Image...",
    //     timerProgressBar: true,
    //     didOpen: () => {
    //       Swal.showLoading();
    //     },
    //   });
    //   executeGenerateUrlUsingDoctorServiceRecaptcha();
    //   executeConfirmFileUploadUsingDoctorServiceRecaptcha();
    //   executeAddImagesToUploadRecaptcha();
    // } else {
    //   generateSignedUrl(imageFile, document, documentGroup)
    //     .then((res: any) => uploadToBucket(imageFile, res.data))
    //     .then((res: any) => confirmUpload(res))
    //     .then((res: any) =>
    //       storeDocument(selectedCard.id, res.uuid, document, documentGroup)
    //     )
    //     .then((res: any) => {
    //       if (res.success) {
    //         Swal.fire({
    //           icon: "success",
    //           title: "Document uploaded sucessfully",
    //           timer: 2000,
    //           showClass: {
    //             popup: "animate__animated animate__fadeInDown",
    //           },
    //           hideClass: {
    //             popup: "animate__animated animate__fadeOutUp",
    //           },
    //         });
    //         reloadPage();
    //       } else {
    //         Swal.fire("Document Upload Failed.", "", "error");
    //       }
    //     })
    //     .catch((error: any) => {
    //       Swal.fire("Document Upload Failed.", "", "error");
    //     });
    // }
  };

  useEffect(() => {
    if (showAddNewDocumentModal) {
      setDocumentGroup("");
      setDocumentType([]);
      setPreview("");
      setDocument("");
    }
  }, [showAddNewDocumentModal]);

  return (
    <>
      <Modal
        open={showAddNewDocumentModal}
        onClose={() => handleCloseDocumentModal()}
      >
        <Box sx={style}>
          <Stack direction="column" spacing={2}>
            {source != "appointment" && (
              <TextField
                select
                fullWidth
                value={documentGroup}
                onChange={handleSelectDocumentGroup}
                label="Select Document Group"
              >
                {programDocuments.length > 0 &&
                  programDocuments.map((option) => (
                    <MenuItem key={option.id} value={option.code}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            )}

            {documentType.length > 0 && source != "appointment" && (
              <TextField
                select
                fullWidth
                value={document}
                onChange={handleSelectDocument}
                label="Select Document Type"
              >
                {documentType.map((option) => (
                  <MenuItem key={option.id} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {source == "appointment" && appointmentDocumentTypes.length > 0 && (
              <TextField
                select
                fullWidth
                value={document}
                onChange={handleSelectDocument}
                label="Select Document Type"
              >
                {appointmentDocumentTypes.map((option) => (
                  <MenuItem key={option.id} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {document && (
              <FileUpload
                buttonTitle="Upload Documents"
                onSuccessCallBack={handleUploadDocument}
                loading={uploadLoading}
              />
            )}
            {/* {document && (
              <Button variant="contained" component="label">
                Upload Document
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleUploadDocument}
                />
              </Button>
            )} */}
            <Box>
              {preview && imageFile && (
                <>
                  <Box textAlign={"center"}>
                    <img
                      width={"250px"}
                      height={"250px"}
                      src={preview}
                      style={{ border: "1px solid", padding: 4 }}
                      alt=""
                    />
                  </Box>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    marginTop={3}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleCloseDocumentModal()}
                    >
                      Close
                    </Button>
                  </Stack>
                </>
              )}
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Modal open={toBeUploaded && uploadConfirmed}>
        <Box sx={style}>
          <Stack alignItems={"center"} gap={5}>
            <Typography variant="h5">Please Wait!</Typography>
            <Stack direction={"row"} gap={4} alignItems={"center"}>
              <CircularProgress color="success" />
              <Typography>Preparing to upload....</Typography>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default AddDocumentModal;
