import {
  Typography,
  Grid,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useProgramDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { ProgramItems } from "../../../../../domain/models/program-items-type";
import { Constant } from "../../../../../constants/constant";
import moment from "moment";
import { useSendToCFAStore } from "../../../../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import dayjs from "dayjs";
import { getFieldValidation } from "../../../../../domain/Utils";
import { FieldNames } from "../../../../../constants/enums/fieldNames";
import GaTextArea from "../../../GaTextArea";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../../../../base";
import useRecaptcha from "../../../../hooks/reCaptcha";
import { useDoctorServiceStore } from "../../../../../store/doctor-service/DoctorServiceStore";
import {
  Doctor,
  DoctorDeliveryAddress,
} from "../../../../../domain/models/doctor";
import { Program } from "../../../../../constants/enums/program";

const TreatmentCycleArr = [
  {
    name: "Daily",
    value: "daily",
  },
  {
    name: "Weekly",
    value: "weekly",
  },

  {
    name: "3 Weekly",
    value: "3 Weekly",
  },
  {
    name: "Fortnightly",
    value: "fortnightly",
  },
  {
    name: "Every 28 Days",
    value: "every 28 days",
  },
  {
    name: "Monthly",
    value: "monthly",
  },
];

const EditProgram: React.FC = () => {
  const { selectedCard } = useEnrollmentMainStore();
  const {
    handleEdit,
    onUpdate,
    masterDropdownData,
    masterDropdownDataLoading,
  } = useProgramDetailStore();
  const { fetchDoctors, doctorList } = useDoctorServiceStore();
  const { programOptions } = useProgramDetailStore();
  const { productOptions, fetchProductOptions } = useSendToCFAStore();

  const {
    captchaToken: fetchProductOptionsToken,
    executeRecaptcha: executeFetchProductOptionsRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchProductOptions");

  const { handleSubmit, control, setValue, watch } = useForm<ProgramItems>({
    mode: "onChange",
  });

  useEffect(() => {
    if (selectedCard.program_id.toString() == Program.EISAI) {
      let params = {
        org_code: selectedCard.org_code,
        dept_code: selectedCard.division_code,
        length: 10000,
      };

      fetchDoctors(params);
    }
  }, []);

  useEffect(() => {
    setValue("product_id", selectedCard.product.name);
    setValue("program_doctor", {
      _id: selectedCard.doctor_uuid,
      full_name: selectedCard.doctor_full_name,
      delivery_addresses: [],
    });
    setValue(
      "transplant_date",
      selectedCard.transplant_date ? dayjs(selectedCard.transplant_date) : null
    );
    setValue("treatment", selectedCard.treatment);

    setValue("diagnosis", selectedCard.diagnosis);
    setValue("dosage", selectedCard.dosage);

    setValue("start_dosage", selectedCard.start_dosage);
    setValue("iop", selectedCard.iop);
    setValue("baselineIOP", selectedCard.baseline_iop);
    setValue("comorbidDiseaseconditions", selectedCard.comorbid_conditions);
    setValue("currentDrugs", selectedCard.prescribed_drugs);
    setValue("numberOfYearsOfGlucoma", selectedCard.disease_duration_years);

    setValue("uom", selectedCard.uom);
    setValue("alt_mobile", selectedCard.alt_mobile);
    setValue("nationality", selectedCard.is_indian_national);
    setValue(
      "doctor_full_name",
      selectedCard.doctor_full_name ? selectedCard?.doctor_full_name : " "
    );

    setValue("hospital", selectedCard.hospital ? selectedCard?.hospital : "");
    setValue("treatment_cycle", selectedCard.treatment_cycle);
    setValue(
      "prescription_date",
      selectedCard.prescription_date
        ? dayjs(selectedCard.prescription_date)
        : null
    );
    setValue(
      "treatment_start_date",
      selectedCard.treatment_start_date
        ? dayjs(selectedCard.treatment_start_date)
        : null
    );
    setValue(
      "next_free_cycle_date",
      selectedCard.next_free_cycle_date
        ? dayjs(selectedCard.next_free_cycle_date)
        : null
    );
    setValue("pack_scheme", selectedCard.pack_scheme);
    setValue("no_of_therapy_months", selectedCard.no_of_therapy_months);
    setValue("no_of_free_months", selectedCard.no_of_free_months);
    setValue(
      "last_infusion_date",
      selectedCard.last_infusion_date
        ? dayjs(selectedCard.last_infusion_date)
        : null
    );
    setValue(
      "next_infusion_date",
      selectedCard.next_infusion_date
        ? dayjs(selectedCard.next_infusion_date)
        : null
    );
    setValue("total_cycles_completed", selectedCard.total_cycles_completed);
    setValue("total_cycles_remaining", selectedCard.total_cycles_remaining);
    setValue("comments", selectedCard.comments);
    executeFetchProductOptionsRecaptcha();
  }, [selectedCard.id]);

  useEffect(() => {
    if (fetchProductOptionsToken) {
      let params = {
        program_id: selectedCard.program_id,
      };
      fetchProductOptions(fetchProductOptionsToken, params);
    }
  }, [fetchProductOptionsToken]);

  const handleClick = (data: ProgramItems) => {
    let payload = {
      product_id: selectedCard.product.id,
      dosage: data.dosage,
      start_dosage: data.start_dosage,
      uom: 0,
      hospital: data.hospital,
      alt_mobile: data.alt_mobile,
      doctor_uuid:
        selectedCard.program_id.toString() == Program.EISAI
          ? data.program_doctor._id
          : null,
      doctor_full_name:
        selectedCard.program_id.toString() == Program.EISAI
          ? data.program_doctor.full_name
          : data.doctor_full_name,
      treatment: data.treatment,
      is_indian_national: data.nationality,
      treatment_cycle: data.treatment_cycle,
      pack_scheme: data.pack_scheme,
      iop: data.iop,
      disease_duration_years: data.numberOfYearsOfGlucoma,
      prescribed_drugs: data.currentDrugs,
      baseline_iop: data.baselineIOP,
      comorbid_conditions: data.comorbidDiseaseconditions,
      no_of_therapy_months: data.no_of_therapy_months,
      no_of_free_months: data.no_of_free_months,
      diagnosis: data.diagnosis,
      transplant_date:
        data.transplant_date &&
        moment(data.transplant_date.toString()).format("YYYY-MM-DD"),
      prescription_date:
        data.prescription_date &&
        moment(data.prescription_date.toString()).format("YYYY-MM-DD"),
      treatment_start_date:
        data.treatment_start_date &&
        moment(data.treatment_start_date.toString()).format("YYYY-MM-DD"),
      next_free_cycle_date:
        data.next_free_cycle_date &&
        moment(data.next_free_cycle_date.toString()).format("YYYY-MM-DD"),
      last_infusion_date:
        data.last_infusion_date &&
        moment(data.last_infusion_date.toString()).format("YYYY-MM-DD"),
      next_infusion_date:
        data.next_infusion_date &&
        moment(data.next_infusion_date.toString()).format("YYYY-MM-DD"),
      total_cycles_completed: data.total_cycles_completed,
      total_cycles_remaining: data.total_cycles_remaining,
      comments: data.comments,
    };
    onUpdate(payload);
  };

  const fetchDiagnosis = (productId: number, programId: number) => {
    const diagnosis = masterDropdownData.master_diagnosis.filter(
      (item) =>
        (item.product_id == productId ||
          item.product_id == selectedCard.product_id) &&
        item.program_id == programId
    );
    return diagnosis ?? [];
  };

  const fetchDosage = (programId: number, productId: number) => {
    let dosages = masterDropdownData.master_dosage.filter(
      (item) =>
        (item.product_id == productId ||
          item.product_id == selectedCard.product_id) &&
        item.program_id == programId
    );

    return dosages ?? [];
  };

  const fetchPackScheme = (
    programId: number,
    productId: number,
    dosage: string | null | undefined
  ) => {
    let packScheme = masterDropdownData.master_schemes.filter(
      (item) => item.program_id == programId
    );

    const extractNumericValue = (value: string): number =>
      parseFloat(value.replace(/[^0-9.]/g, ""));

    const dosageValue = dosage
      ? extractNumericValue(dosage)
      : selectedCard?.dosage
      ? extractNumericValue(selectedCard.dosage)
      : NaN;
    if (productId == 10 && !isNaN(dosageValue) && dosageValue <= 14) {
      packScheme = packScheme.filter((item) => item.name !== "1 Plus 3");
    }

    return packScheme ?? [];
  };

  const getDoctorAddress = (data: DoctorDeliveryAddress[] = []) => {
    if (!data || data.length === 0) return null;

    const bothPresent = data.find((obj) => obj.area && obj.state);
    if (bothPresent) return `${bothPresent.area} - ${bothPresent.state}`;

    const areaPresent = data.find((obj) => obj.area);
    if (areaPresent) return areaPresent.area;

    const statePresent = data.find((obj) => obj.state);
    if (statePresent) return statePresent.state;

    return null;
  };

  return (
    <Stack overflow="scroll" height="65vh">
      <Stack
        alignItems={"center"}
        direction={"row"}
        justifyContent={"space-between"}
        width={"42%"}
        position={"fixed"}
        zIndex={10}
        bgcolor={"white"}
        paddingBottom={2}
      >
        <Stack>
          <Typography>Program Details</Typography>
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Button
            sx={{ borderRadius: "45px" }}
            variant="contained"
            onClick={handleSubmit(handleClick)}
          >
            Update
          </Button>
          <Button
            color="secondary"
            variant="text"
            onClick={() => handleEdit(0)}
          >
            CLOSE
          </Button>
        </Stack>
      </Stack>
      <Grid container spacing={3} mt={3}>
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.ProductId
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.ProductId
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Product
              </Typography>
              <Controller
                name="product_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    size="small"
                    disabled
                    defaultValue={selectedCard.product.id}
                    fullWidth
                    value={value}
                    // onChange={onChange}
                    error={error && true}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.ProductId
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.IsIndianNational
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.IsIndianNational
          )?.visibility && (
            <Grid item xs={6} marginTop={2}>
              <Controller
                name="nationality"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl>
                    <Stack
                      flexDirection={"row"}
                      gap={1}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        <Typography color={"text.secondary"} variant="caption">
                          Is an Indian National?
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel
                          value={1}
                          checked={value == 1}
                          control={<Radio size="small" />}
                          label={<Typography variant="caption">Yes</Typography>}
                        />
                        <FormControlLabel
                          value={0}
                          checked={value == 0}
                          control={<Radio size="small" />}
                          label={<Typography variant="caption">No</Typography>}
                        />
                      </RadioGroup>
                    </Stack>
                  </FormControl>
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.IsIndianNational
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(programOptions[0].config_data, FieldNames.Dosage) &&
          getFieldValidation(programOptions[0].config_data, FieldNames.Dosage)
            ?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Dosage
              </Typography>
              <Controller
                name="dosage"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    defaultValue={selectedCard.dosage}
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  >
                    {fetchDosage(
                      selectedCard.program_id,
                      selectedCard.product_id
                    ).length > 0 &&
                      !masterDropdownDataLoading &&
                      fetchDosage(
                        selectedCard.program_id,
                        selectedCard.product_id
                      ).map((option) => (
                        <MenuItem key={option.program_id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.Dosage
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {programOptions.length > 0 &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.PackScheme
          ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.PackScheme
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Pack Scheme
              </Typography>
              <Controller
                name="pack_scheme"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    value={value}
                    size="small"
                    error={error && true}
                    defaultValue={selectedCard.pack_scheme}
                    helperText={error?.message}
                    onChange={onChange}
                  >
                    {fetchPackScheme(
                      selectedCard.program_id,
                      selectedCard.product_id,
                      watch("dosage")
                    ).length > 0 &&
                      !masterDropdownDataLoading &&
                      fetchPackScheme(
                        selectedCard.program_id,
                        selectedCard.product_id,
                        watch("dosage")
                      ).map((option) => (
                        <MenuItem key={option.program_id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.PackScheme
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.StartDosage
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.StartDosage
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Start Dosage
              </Typography>
              <Controller
                name="start_dosage"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    defaultValue={selectedCard.start_dosage}
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  >
                    {fetchDosage(
                      selectedCard.program_id,
                      selectedCard.product_id
                    ).length > 0 &&
                      !masterDropdownDataLoading &&
                      fetchDosage(
                        selectedCard.program_id,
                        selectedCard.product_id
                      ).map((option) => (
                        <MenuItem key={option.program_id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.StartDosage
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(programOptions[0].config_data, FieldNames.IOP) &&
          getFieldValidation(programOptions[0].config_data, FieldNames.IOP)
            ?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                IOP
              </Typography>
              <Controller
                name="iop"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    size="small"
                    error={error && true}
                    // defaultValue={selectedCard.pack_scheme}
                    helperText={error?.message}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.IOP
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.DiseaseDuration
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.DiseaseDuration
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                No.of Years of Glaucoma
              </Typography>
              <Controller
                name="numberOfYearsOfGlucoma"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    type="number"
                    size="small"
                    error={error && true}
                    // defaultValue={selectedCard.pack_scheme}
                    helperText={error?.message}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.DiseaseDuration
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.BaselineIop
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.BaselineIop
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Baseline IOP
              </Typography>
              <Controller
                name="baselineIOP"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    type="number"
                    size="small"
                    error={error && true}
                    // defaultValue={selectedCard.pack_scheme}
                    helperText={error?.message}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.BaselineIop
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.PrescribedDrugs
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.PrescribedDrugs
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Current Drugs for Glaucoma
              </Typography>
              <Controller
                name="currentDrugs"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <GaTextArea
                    value={value}
                    onChange={onChange}
                    placeholder=""
                    error={error && true}
                    errorMessage={error?.message}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.PrescribedDrugs
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.ComorbidCondtitions
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.ComorbidCondtitions
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Comorbid Disease conditions
              </Typography>
              <Controller
                name="comorbidDiseaseconditions"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <GaTextArea
                    value={value}
                    onChange={onChange}
                    placeholder=""
                    error={error && true}
                    errorMessage={error?.message}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.ComorbidCondtitions
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.AltMobile
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.AltMobile
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Secondary Contact
              </Typography>
              <Controller
                name="alt_mobile"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  />
                )}
                rules={{
                  pattern: {
                    value: Constant.CORRECT_MOBILE.VALUE,
                    message: Constant.CORRECT_MOBILE.MESSAGE,
                  },
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.AltMobile
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.DoctorFullName
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.DoctorFullName
          )?.visibility &&
          selectedCard.program_id.toString() == Program.EISAI && (
            <Grid item xs={6}>
              <Typography variant="caption" display="block" gutterBottom>
                Doctor
              </Typography>

              <Controller
                name="program_doctor"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    onChange={(event: any, newValue: Doctor | null) => {
                      onChange(newValue ? newValue : null);
                    }}
                    size="small"
                    value={value}
                    options={doctorList}
                    getOptionLabel={(option) =>
                      `${option.full_name} - (${getDoctorAddress(
                        option.delivery_addresses
                      )})`
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option._id}>
                        {`${option.full_name} - (${getDoctorAddress(
                          option.delivery_addresses
                        )})`}
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        error={error && true}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.DoctorFullName
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.DoctorFullName
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.DoctorFullName
          )?.visibility &&
          selectedCard.program_id.toString() != Program.EISAI && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Doctor
              </Typography>
              <Controller
                name="doctor_full_name"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.DoctorFullName
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.Hospital
        ) &&
          getFieldValidation(programOptions[0].config_data, FieldNames.Hospital)
            ?.visibility && (
            <Grid item xs={6}>
              <Typography variant="caption" color={"text.secondary"}>
                Hospital/Clinic
              </Typography>
              <Controller
                name="hospital"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.Hospital
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.Diagnosis
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.Diagnosis
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Diagnosis
              </Typography>
              <Controller
                name="diagnosis"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    defaultValue={selectedCard.diagnosis}
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  >
                    {fetchDiagnosis(
                      selectedCard.program.id,
                      selectedCard.program_id
                    ).length > 0 &&
                      !masterDropdownDataLoading &&
                      fetchDiagnosis(
                        selectedCard.program.id,
                        selectedCard.program_id
                      ).map((option) => (
                        <MenuItem key={option.program_id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.Diagnosis
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.TreatmentCycle
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TreatmentCycle
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Treatment Cycle
              </Typography>
              <Controller
                name="treatment_cycle"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    defaultValue={selectedCard.treatment_cycle}
                    fullWidth
                    select
                    variant="outlined"
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  >
                    {TreatmentCycleArr.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.TreatmentCycle
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}

        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.NoOfTherapyMonths
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.NoOfTherapyMonths
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Number of Therapy Months
              </Typography>
              <Controller
                name="no_of_therapy_months"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.NoOfTherapyMonths
                      )?.required ?? false,
                    message: "Field Required",
                  },
                  pattern: {
                    value: Constant.NUMBER_ONLY.VALUE,
                    message: Constant.NUMBER_ONLY.MESSAGE,
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.NoOfFreeMonths
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.NoOfFreeMonths
          )?.visibility && (
            <Grid item xs={6} columnSpacing={3} rowGap={2}>
              <Typography color={"text.secondary"} variant="caption">
                Number of Free Months
              </Typography>
              <Controller
                name="no_of_free_months"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.NoOfFreeMonths
                      )?.required ?? false,
                    message: Constant.FIELD_REQUIRED,
                  },
                  pattern: {
                    value: Constant.NUMBER_ONLY.VALUE,
                    message: Constant.NUMBER_ONLY.MESSAGE,
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.PrescriptionDate
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.PrescriptionDate
          )?.visibility && (
            <Grid xs={6} item>
              <Typography color={"text.secondary"} variant="caption">
                Prescription Date
              </Typography>
              <Controller
                name="prescription_date"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="ll"
                      value={value}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                      onChange={onChange}
                    />
                  </LocalizationProvider>
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.PrescriptionDate
                      )?.required ?? false,
                    message: Constant.FIELD_REQUIRED,
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.TransplantDate
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TransplantDate
          )?.visibility && (
            <Grid xs={6} item>
              <Typography color={"text.secondary"} variant="caption">
                Transplant Date
              </Typography>
              <Controller
                name="transplant_date"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="ll"
                      value={value}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                      onChange={onChange}
                    />
                  </LocalizationProvider>
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.TransplantDate
                      )?.required ?? false,
                    message: Constant.FIELD_REQUIRED,
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.TreatmentStartDate
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TreatmentStartDate
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Treatment Start Date
              </Typography>
              <Controller
                name="treatment_start_date"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="ll"
                      value={value}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                      onChange={onChange}
                    />
                  </LocalizationProvider>
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.TreatmentStartDate
                      )?.required ?? false,
                    message: Constant.FIELD_REQUIRED,
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.NextFreeCycleDate
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.NextFreeCycleDate
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Next free cycle Date
              </Typography>
              <Controller
                name="next_free_cycle_date"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="ll"
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                    />
                  </LocalizationProvider>
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.NextFreeCycleDate
                      )?.required ?? false,
                    message: Constant.FIELD_REQUIRED,
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.NextInfusionDate
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.NextInfusionDate
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Next Infusion Date
              </Typography>
              <Controller
                name="next_infusion_date"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="ll"
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                      value={value}
                      onChange={onChange}
                    />
                  </LocalizationProvider>
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.NextInfusionDate
                      )?.required ?? false,
                    message: Constant.FIELD_REQUIRED,
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.LastInfusionDate
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.LastInfusionDate
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Last Infusion Date
              </Typography>
              <Controller
                name="last_infusion_date"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="ll"
                      value={value}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                      onChange={onChange}
                    />
                  </LocalizationProvider>
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.LastInfusionDate
                      )?.required ?? false,
                    message: Constant.FIELD_REQUIRED,
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.TotalCyclesCompleted
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TotalCyclesCompleted
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Total Cycles Completed
              </Typography>
              <Controller
                name="total_cycles_completed"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.TotalCyclesCompleted
                      )?.required ?? false,
                    message: Constant.FIELD_REQUIRED,
                  },
                  pattern: {
                    value: Constant.NUMBER_ONLY.VALUE,
                    message: Constant.NUMBER_ONLY.MESSAGE,
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.TotalCyclesRemaining
        ) &&
          getFieldValidation(
            programOptions[0].config_data,
            FieldNames.TotalCyclesRemaining
          )?.visibility && (
            <Grid item xs={6}>
              <Typography color={"text.secondary"} variant="caption">
                Total Cycles Remaining
              </Typography>
              <Controller
                name="total_cycles_remaining"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    size="small"
                    error={error && true}
                    helperText={error?.message}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.TotalCyclesRemaining
                      )?.required ?? false,
                    message: "Field Required",
                  },
                  pattern: {
                    value: Constant.NUMBER_ONLY.VALUE,
                    message: Constant.NUMBER_ONLY.MESSAGE,
                  },
                }}
              />
            </Grid>
          )}
        {getFieldValidation(
          programOptions[0].config_data,
          FieldNames.Comments
        ) &&
          getFieldValidation(programOptions[0].config_data, FieldNames.Comments)
            ?.visibility && (
            <Grid item xs={12}>
              <Typography color={"text.secondary"} variant="caption">
                Comments
              </Typography>
              <Controller
                name="comments"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    rows={4}
                    multiline
                    fullWidth
                    size="small"
                    aria-multiline
                    value={value}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: {
                    value:
                      getFieldValidation(
                        programOptions[0].config_data,
                        FieldNames.Comments
                      )?.required ?? false,
                    message: "Field Required",
                  },
                }}
              />
            </Grid>
          )}
      </Grid>
    </Stack>
  );
};
export default EditProgram;
